// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import axiosex from "axios";
import { dispatch } from "../index";

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  allPopup: [],
  allRankingHistoryByID: [],
  allRanking: [],
  gameListUrl: [],
  allProvider: [],
  playerCash: [],
  bannerList: [],
  wheelSetting: [],
  wheelPoint: [],
  friendInvite: [],
  userAvailablePromotion: [],
  withdrawSetting: [],
  bankList: [],
  transferBankList: [],
  websiteSetting: [],
  transactionHistory: [],
  withdrawHistory: [],
  playerLoginLink: [],
  playerBalance: [],
  tmnData: [],
  cashbackSetting: [],
  cashback: [],
  inviteSetting: [],
  betflixStatus: [],
  totalUsers: [],
  singleUsers: [],
  agentInfo: [],
  agentฺBalance: [],
  singleAdmin: [],
  singlePromo: [],
  todayPromotionReceived: [],
  allPromotionReceived: [],
  allPromo: [],
  singleCreditCode: [],
  allCreditCode: [],
  todayUsers: [],
  byIdTransDW: [],
  allTransDW: [],
  allAdmin: [],
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    getAllRankingHistoryByIDSuccess(state, action) {
      state.allRankingHistoryByID = action.payload;
    },

    getAllRankingSuccess(state, action) {
      state.allRanking = action.payload;
    },

    getWheelSettingSuccess(state, action) {
      state.wheelSetting = action.payload;
    },

    getPlayerCashSuccess(state, action) {
      state.playerCash = action.payload;
    },

    getWheelPointSuccess(state, action) {
      state.wheelPoint = action.payload;
    },

    getGameListUrlSuccess(state, action) {
      state.gameListUrl = action.payload;
    },

    getAllProviderSuccess(state, action) {
      state.allProvider = action.payload;
    },

    getFriendInviteSuccess(state, action) {
      state.friendInvite = action.payload;
    },

    getUserAvailablePromotionSuccess(state, action) {
      state.userAvailablePromotion = action.payload;
    },
    getWithdrawSettingSuccess(state, action) {
      state.withdrawSetting = action.payload;
    },

    getBankListSuccess(state, action) {
      state.bankList = action.payload;
    },

    getTransferBankListSuccess(state, action) {
      state.transferBankList = action.payload;
    },

    getTodayPromotionReceivedSuccess(state, action) {
      state.todayPromotionReceived = action.payload;
    },

    getAllPromotionReceivedSuccess(state, action) {
      state.allPromotionReceived = action.payload;
    },

    getWebsiteSettingSuccess(state, action) {
      state.websiteSetting = action.payload;
    },

    getBannerListSuccess(state, action) {
      state.bannerList = action.payload;
    },

    getAllPopupSuccess(state, action) {
      state.allPopup = action.payload;
    },

    getTransactionHistorySuccess(state, action) {
      state.transactionHistory = action.payload;
    },

    getWithdrawHistorySuccess(state, action) {
      state.withdrawHistory = action.payload;
    },

    getTMNDataSuccess(state, action) {
      state.tmnData = action.payload;
    },

    getCashbackSettingSuccess(state, action) {
      state.cashbackSetting = action.payload;
    },

    getCashbackSuccess(state, action) {
      state.cashback = action.payload;
    },

    getInviteSettingSuccess(state, action) {
      state.inviteSetting = action.payload;
    },

    getPlayerLoginLinkSuccess(state, action) {
      state.playerLoginLink = action.payload;
    },

    getPlayerBalanceSuccess(state, action) {
      state.playerBalance = action.payload;
    },

    // GET USERS STYLE 1

    getBetflixStatus(state, action) {
      state.betflixStatus = action.payload;
    },

    getAgentInfoSuccess(state, action) {
      state.agentInfo = action.payload;
    },

    getAgentBalanceSuccess(state, action) {
      state.agentBalance = action.payload;
    },

    getAllAdminSuccess(state, action) {
      state.allAdmin = action.payload;
    },

    getUsersListStyle1Success(state, action) {
      state.usersS1 = action.payload;
    },

    getTotalUsersSuccess(state, action) {
      state.totalUsers = action.payload;
    },

    getPromoByIDSuccess(state, action) {
      state.singlePromo = action.payload;
    },

    getAllPromoSuccess(state, action) {
      state.allPromo = action.payload;
    },

    getCreditCodeByIDSuccess(state, action) {
      state.singleCreditCode = action.payload;
    },

    getAllCreditCodeSuccess(state, action) {
      state.allCreditCode = action.payload;
    },

    getUsersByIDSuccess(state, action) {
      state.singleUsers = action.payload;
    },

    getAdminByIDSuccess(state, action) {
      state.singleAdmin = action.payload;
    },

    getTodayUsersSuccess(state, action) {
      state.todayUsers = action.payload;
    },

    getAllTransactionDWSuccess(state, action) {
      state.allTransDW = action.payload;
    },

    getByIdTransactionDWSuccess(state, action) {
      state.byIdTransDW = action.payload;
    },

    // GET USERS STYLE 2
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllRankingHistoryByID(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/ranking/getrankhistorybyid/${id}`
      );
      dispatch(slice.actions.getAllRankingHistoryByIDSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllRanking() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.s24hr.com/ranking`);
      dispatch(slice.actions.getAllRankingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getWheelSetting() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/setting/wheelsetting`
      );
      dispatch(slice.actions.getWheelSettingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getWheelPoint(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/wheel/point/${id}`
      );
      dispatch(slice.actions.getWheelPointSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGameListUrl() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/gamelogin/getgamelist`
      );
      dispatch(slice.actions.getGameListUrlSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllProvider(id) {
  return async () => {
    try {
      const response = await axiosex.post(
        `https://api.s24hr.com/gamelogin/getprovider/${id.id}`
      );
      dispatch(slice.actions.getAllProviderSuccess(response.data.providerList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFriendInvite(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/agentbalance/invitesummaryclient/${id}`
      );
      dispatch(slice.actions.getFriendInviteSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserAvailablePromotion(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/usersystem/getavailablepromotion/${id.id}`
      );
      dispatch(slice.actions.getUserAvailablePromotionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getWithdrawSetting() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/setting/withdraw`
      );
      dispatch(slice.actions.getWithdrawSettingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBankList() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.s24hr.com/bank`);
      dispatch(slice.actions.getBankListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTranferBankList() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/8hwe8vhevv/banklist`
      );
      dispatch(slice.actions.getTransferBankListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTodayPromotionReceived(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/usersystem/checkreceivedpromotion/${id.id}`
      );
      dispatch(
        slice.actions.getTodayPromotionReceivedSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllPromotionReceived(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/usersystem/checkallreceivedpromotion/${id.id}`
      );
      dispatch(
        slice.actions.getAllPromotionReceivedSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBannerList() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/setting/banner`
      );
      dispatch(slice.actions.getBannerListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPopupList() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.s24hr.com/setting/popup`);
      dispatch(slice.actions.getAllPopupSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getWebsiteSetting() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/setting/websetting`
      );
      dispatch(slice.actions.getWebsiteSettingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTMNData() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.s24hr.com/bank/tmnfront`);
      dispatch(slice.actions.getTMNDataSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPlayerLoginLink(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/gamelogin/getlogin/${id.id}`
      );
      dispatch(slice.actions.getPlayerLoginLinkSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTransactionHistory(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/userbalance/history/${id}`
      );
      dispatch(slice.actions.getTransactionHistorySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getWithdrawHistory(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/userbalance/withdrawhistory/${id}`
      );
      dispatch(slice.actions.getWithdrawHistorySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPlayerBalance(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/userbalance/balance/${id.id}`
      );
      dispatch(slice.actions.getPlayerBalanceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPlayerCash(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/userbalance/cash/${id.id}`
      );
      dispatch(slice.actions.getPlayerCashSuccess(response?.data?.cashWallet));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBetflixStatus() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.bfx.fail/v4/status`);
      dispatch(slice.actions.getBetflixStatusSuccess(response.data.status));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllPromo() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/promotion/getavailable`
      );
      dispatch(slice.actions.getAllPromoSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCashbackSetting() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/setting/cashback`
      );
      dispatch(slice.actions.getCashbackSettingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCashback(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/agentbalance/cashbackclientcheck/${id.id}`
      );
      dispatch(slice.actions.getCashbackSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInviteSetting() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/setting/invite`
      );
      dispatch(slice.actions.getInviteSettingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAgentInfo() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.s24hr.com/agentinfo`);
      dispatch(slice.actions.getAgentInfoSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAgentBalance() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.s24hr.com/agentbalance`);
      dispatch(slice.actions.getAgentBalanceSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPromoByID(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/promotion/getbyid/${id}`
      );
      dispatch(slice.actions.getPromoByIDSuccess(response?.data?.data[0]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllCreditCode() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.s24hr.com/creditcode`);
      dispatch(slice.actions.getAllCreditCodeSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCreditCodeByID(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/creditcode/${id.id}`
      );
      dispatch(slice.actions.getCreditCodeByIDSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUsersByID(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/usersystem/${id.id}`,
        {
          headers: {
            "Cache-Control": "no-cache, no-store, must-revalidate", // ป้องกันการแคช
            Pragma: "no-cache", // สำหรับเบราว์เซอร์เก่า
            Expires: "0", // ตั้งค่า Expires เป็น 0
          },
        }
      );
      dispatch(slice.actions.getUsersByIDSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllTransactionDW() {
  return async () => {
    try {
      const response = await axiosex.get("https://api.s24hr.com/8hwe8vhevv");
      dispatch(slice.actions.getAllTransactionDWSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getByIdTransactionDW(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/8hwe8vhevv/${id}`
      );
      dispatch(slice.actions.getByIdTransactionDWSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
