// third-party
import jwt from "jsonwebtoken";
import axiosex from "axios";
// project imports
import services from "utils/mockAdapter";
import { JWT_API } from "config";

// constant
const JWT_SECRET = JWT_API.secret;
const JWT_EXPIRES_TIME = JWT_API.timeout;

const delay = (timeout) => new Promise((res) => setTimeout(res, timeout));
let users = [];

// ==============================|| MOCK SERVICES ||============================== //

services.onPost("/api/account/login").reply(async (request) => {
  try {
    await delay(500);

    const { username, password } = JSON.parse(request.data);

    if (
      window.localStorage.getItem("users") !== undefined &&
      window.localStorage.getItem("users") !== null
    ) {
      const localUsers = window.localStorage.getItem("users");
      users = JSON.parse(localUsers);
    }

    const user = await axiosex.post("https://api.s24hr.com/playerlogin", {
      username,
      password,
    });

    //const user = users.find((_user) => _user.username === username);

    if (user.data === "notfound") {
      return [400, { message: "ชื่อผู้ใช้งานหรือรหัสผ่านผิด!" }];
    }

    if (user.data === "wrongpass") {
      return [400, { message: "รหัสผ่านผิด!" }];
    }

    if (user.data === "banned") {
      return [400, { message: "พบปัญหาในการเข้าสู่ระบบ กรุณาติดต่อแอดมิน" }];
    }

    const serviceToken = jwt.sign({ userId: user.data.id }, JWT_SECRET, {
      expiresIn: JWT_EXPIRES_TIME,
    });

    return [
      200,
      {
        serviceToken,
        user: {
          id: user.data.id,
          username: user.data.username,
          name: user.data.book_name,
          status: user.data.status,
          credit: user.data.credit,
          book_number: user.data.book_number,
          bank_name: user.data.bank_name,
          PromotionID: user.data.PromotionID,
          PromotionDetail: user.data.PromotionDetail,
          PromotionStatus: user.data.PromotionStatus,
        },
      },
    ];
  } catch (err) {
    console.error(err);
    return [500, { message: "Server Error" }];
  }
});

services.onPost("/api/account/register").reply(async (request) => {
  try {
    await delay(500);

    const {
      id,
      username,
      password,
      confirmPassword,
      bank_name,
      book_number,
      book_name,
      inviterID,
      registerFrom,
    } = JSON.parse(request.data);

    if (!username || !password) {
      return [400, { message: "กรุณากรอกเบอร์โทรศัทพ์ และ รหัสผ่าน" }];
    }

    if (password !== confirmPassword) {
      return [400, { message: "รหัสผ่านไม่ตรงกัน" }];
    }

    if (
      !bank_name ||
      !book_number ||
      !book_name ||
      bank_name === "กำลังตรวจสอบ..." ||
      bank_name === "อัตโนมัติ"
    ) {
      return [400, { message: "กรุณากรอกข้อมูลบัญชีธนาคาร" }];
    }

    const user = await axiosex.post(
      "https://api.s24hr.com/usersystem/checkregister",
      {
        username,
        book_number,
        bank_name,
        book_name,
      }
    );

    if (user.data === "duplicatebooknumberandusername") {
      return [400, { message: "ชื่อผู้ใช้งานกับบัญชีธนาคารซ้ำในระบบ!" }];
    } else if (user.data === "duplicatebooknumber") {
      return [400, { message: "บัญชีธนาคารซ้ำในระบบ!" }];
    } else if (user.data === "duplicateusername") {
      return [400, { message: "ชื่อผู้ใช้งานซ้ำในระบบ!" }];
    } else if (user.data === "duplicatebookname") {
      return [400, { message: "ชื่อบัญชีซ้ำในระบบ!" }];
    } else {
      await axiosex.post("https://api.s24hr.com/usersystem", {
        username,
        password,
        bank_name,
        book_number,
        book_name,
        inviterID,
        registerFrom,
      });
    }

    users = [
      ...users,
      {
        id,
        username,
        password,
        bank_name,
        book_number,
        book_name,
        name: `${book_name}`,
      },
    ];

    return [200, users];
  } catch (err) {
    console.error(err);
    return [500, { message: "Server Error" }];
  }
});

services.onGet("/api/account/me").reply(async (request) => {
  try {
    const { Authorization } = request.headers;
    if (!Authorization) {
      return [401, { message: "Token Missing" }];
    }

    if (
      window.localStorage.getItem("users") !== undefined &&
      window.localStorage.getItem("users") !== null
    ) {
      const localUsers = window.localStorage.getItem("users");
      users = JSON.parse(localUsers);
    }

    const serviceToken = Authorization.toString();

    const jwData = jwt.verify(serviceToken, JWT_SECRET);
    const { userId } = jwData;
    const user = await axiosex.post(
      "https://api.s24hr.com/playerlogin/check/",
      {
        userId,
      }
    );

    //const user = users.find((_user) => _user.AdminID === userId);

    if (user.data === "notfound") {
      return [401, { message: "Invalid Token" }];
    }
    return [
      200,
      {
        user: {
          id: user.data.id,
          username: user.data.username,
          name: user.data.book_name,
          status: user.data.status,
          credit: user.data.credit,
          book_number: user.data.book_number,
          bank_name: user.data.bank_name,
          PromotionID: user.data.PromotionID,
          PromotionDetail: user.data.PromotionDetail,
          PromotionStatus: user.data.PromotionStatus,
        },
      },
    ];
  } catch (err) {
    return [500, { message: "Server Error XX" + err }];
  }
});
